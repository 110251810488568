import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-light light-blue lighten-4 d-sm-none">
          <a className="navbar-brand" href="/">
            <img
              src="/uploads/voorlichtingsles-logo.png"
              height="40"
              alt="Voorlichtingsles Volwassen Worden"
            />
          </a>
          <button
            className="navbar-toggler toggler-example"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="dark-blue-text">
              <i className="fas fa-bars fa-1x" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent1"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lessen"
                >
                  Lessen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen"
                >
                  Lesmaterialen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/multimedia"
                >
                  Multimedia aanpak
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/service"
                >
                  Service
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header className="bg-blue d-none d-sm-block pt-3">
          <div
            className="container-fluid"
            style={{
              "border-bottom": "rgb(238,38,110) 7px solid"
            }}
          >
            <div className="container">
              <div className="row hideMegaMenu">
                <div className="col-12">
                  <img
                    src="/uploads/voorlichtingsles-logo.png"
                    alt="Voorlichtingsles Volwassen Worden"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a className="show-menu" href="/" data-value="-1">
                    <i className="fas fa-home fa-2x" />
                  </a>
                  <a className="show-menu" href="/lessen" data-value="0">
                    Lessen
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen"
                    data-value="1"
                  >
                    Lesmaterialen
                  </a>
                  <a
                    className="show-menu"
                    href="/multimedia"
                    data-value="2"
                  >
                    Multimedia aanpak
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    data-value="3"
                  >
                    Lespakket gratis bestellen
                  </a>
                  <a className="show-menu" href="/service" data-value="4">
                    Service
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="megamenu container-fluid"
            data-show="0"
            style={{
              "z-index": "99999",
              position: "absolute",
              display: "none"
            }}
          >
            <div id="megamenu_0" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_richtlijnen.jpg"
                      className="img-fluid"
                      alt="Richtlijnen"
                    />
                    <a
                      href="/lessen/richtlijnen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Richtlijnen voor de seksuele voorlichting
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_lesvoorbereiding.jpg"
                      className="img-fluid"
                      alt="Lesvoorbereiding"
                    />
                    <a
                      href="/lessen/lesvoorbereiding"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Lesvoorbereiding
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_tips.jpg"
                      className="img-fluid"
                      alt="Tips"
                    />
                    <a
                      href="/lessen/tips"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Tips voor de seksuele voorlichtingslessen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_1" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/themagebieden"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Themagebieden
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                            className="d-block mt-1 tab-menu"
                          >
                            Ontwikkeling en puberteit
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/geslachtsorganen"
                            className="d-block mt-1 tab-menu"
                          >
                            Geslachtsorganen
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatiecyclus
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/menstruatie-hygine"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatie hygiëne
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/tampongebruik"
                            className="d-block mt-1 tab-menu"
                          >
                            Tampongebruik
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Speciale pakketten
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor meisjes
                          </a>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor jongens
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Lespakket gratis bestellen
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                            className="d-block mt-1 tab-menu"
                          >
                            Docentenhandleiding
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_2" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_instructiefilm.jpg"
                      className="img-fluid"
                      alt="Instructiefilm"
                    />
                    <a
                      href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Instructiefilm over seksuele voorlichting «Volwassen
                      worden»
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_kennistest.jpg"
                      className="img-fluid"
                      alt="Online kennistest"
                    />
                    <a
                      href="/multimedia/online-kennistests"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Online kennistest
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_oefeningen.jpg"
                      className="img-fluid"
                      alt="Oefenbladen"
                    />
                    <a
                      href="/multimedia/oefenbladen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Oefenbladen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_3" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_4_primaironderwijs.jpg"
                      className="img-fluid"
                      alt="Bestel nu gratis"
                    />
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Bestel nu gratis
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_4" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_vragen.jpg"
                      className="img-fluid"
                      alt="Belangrijke vragen"
                    />
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke vragen over menstruatie en menstruatiehygiëne
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_links.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                    <a
                      href="/service/belangrijke-links"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke links
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_contact.jpg"
                      className="img-fluid"
                      alt="Contact"
                    />
                    <a
                      href="/service/contact"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
          </div>
        </header>
        <main className="hideMegaMenu">
          <div className="container-fluid opening mb-3">
            <div className="container bg-paars">
              <div className="row">
                <div className="col-md-6 px-4 py-3">
                  <h1 className="h1-responsive text-white">Privacybeleid</h1>
                </div>
                <div className="col-md-6">
                  <img
                    src="/uploads/img_4459_6_service.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid hideMegaMenu">
            <div className="container">
              <div id="breadcrumb">
                <div className="bc my-3 d-none d-sm-block ">
                  <a href="/">Home</a> &gt;{" "}
                  <a href="/service">Service &amp; veel gestelde vragen</a>{" "}
                  &gt; <a href="/service/privacybeleid">Privacybeleid</a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div id="content">
                    <h1 className="h1-responsive my-3  ">Privacybeleid</h1>
                    <p className="lead hide" />
                    <p />
                    <p>
                    Johnson &amp; Johnson Consumer BV is begaan met uw privacy en wil dat u vertrouwd bent met hoe wij informatie verzamelen, gebruiken en doorgeven. Dit Privacybeleid omschrijft onze praktijken in verband met de informatie die wij of onze dienstverleners verzamelen via de door ons beheerde website of applicatie (hierna de ‘<strong>Dienst</strong>’ genoemd) waar vandaan u dit Privacybeleid hebt bereikt. Door ons persoonsgegevens te verstrekken of door de Dienst te gebruiken, bevestigt u dat u dit Privacybeleid gelezen en begrepen heeft.
                    </p>
                    <h3>GEBRUIK DOOR MINDERJARIGEN</h3>
                    <p>
                    De Dienst is niet gericht op personen jonger dan 16.&nbsp;Wij vragen van deze personen geen persoonsgegevens via de Dienst te verstrekken. Als uw kind persoonsgegevens heeft ingediend en u wilt verzoeken dat dergelijke persoonsgegevens worden verwijderd, neem dan contact met ons op zoals onder ‘Contact met ons opnemen’ wordt uitgelegd.
                    </p>
                    <h3>VERZAMELING VAN INFORMATIE</h3>
                    <p>
                    We kunnen u vragen om persoonsgegevens te versturen zodat u kunt gebruikmaken van bepaalde functionaliteiten (zoals de nieuwsbrief, tips/suggesties, of de verwerking van uw bestelling) of om deel te nemen aan een bepaalde activiteit (zoals wedstrijden of andere promotionele acties). U zult worden geïnformeerd welke gegevens vereist zijn en welke optioneel zijn.
                    </p>
                    <p>
                    Wij kunnen de gegevens die u hebt verstrekt combineren met andere informatie die wij over u hebben verzameld, zowel on- als offline, inclusief bijvoorbeeld uw aankoopgeschiedenis. We kunnen ze ook combineren met informatie die wij over u krijgen van andere Kenvue filialen.
                    </p>
                    <p>
                    Als u ons persoonsgegevens over een andere persoon verstrekt, verklaart u dat u daar de toelating voor heeft en dat u ons toelaat om die informatie te gebruiken in overeenstemming met dit Privacybeleid.
                    </p>
                    <h3>GEVOELIGE INFORMATIE</h3>
                    <p>
                    Behalve als wij het specifiek vereisen of verzoeken, vragen wij u ons geen gevoelige persoonsgegevens te verstrekken of aan ons bekend te maken (bijv. nationale identificatienummers, informatie met betrekking tot ras of etnische afkomst, politieke mening, religie of filosofische overtuiging, gezondheid of medische aandoeningen, seksueel gedrag of seksuele geaardheid, criminele achtergrond of lidmaatschap van een vakbond, of biometrische of genetische gegevens) op of via de Dienst of op andere wijze.
                    </p>
                    <h3>AUTOMATISCHE VERZAMELING EN GEBRUIK VAN INFORMATIE</h3>
                    <p>
                      Wij en onze dienstverleners kunnen bepaalde gegevens
                      automatisch verzamelen terwijl u&nbsp;de Dienst bezoekt.
                      Lees het <a href="https://www.voorlichtingsles.nl/service/cookies/">Cookiebeleid</a>{" "}
                    voor gedetailleerde informatie over de cookies en andere traceertechnologieën die we op de Dienst&nbsp;gebruiken. Tenzij anders toegelaten door de toepasselijke wetgeving, plaatsen we cookies nadat we jouw toestemming gekregen hebben via de cookiebanner of het voorkeurenmenu. Het Cookiebeleid omvat informatie over hoe u deze technologieën kunt uitschakelen.
                    </p>
                    <p>
                    Wij en onze dienstverleners kunnen ook automatisch informatie verzamelen en gebruiken, op de volgende manieren:
                    </p>
                    <p>
                      <strong>Via uw browser</strong>: Bepaalde informatie wordt door de meeste browsers verzameld, zoals uw Media Access Control (MAC)-adres, computertype (Windows of Mac), uw schermresolutie, de naam en versie van uw besturingssysteem en het type en de versie van uw internetbrowser. We kunnen soortgelijke informatie verzamelen, zoals het type&nbsp;van uw toestel en de unieke toestelidentificatie, als u via een mobiel toestel&nbsp;de Dienst&nbsp;gebruikt. We gebruiken deze informatie om te verzekeren dat de Dienst correct functioneert.
                    </p>
                    <p>
                      <strong>IP-adres</strong>: Uw IP-adres is een getal dat automatisch door uw internetprovider aan uw computer wordt toegekend. Een IP-adres wordt automatisch geïdentificeerd en vastgelegd in onze serverlogbestanden telkens als een gebruiker de Dienst bezoekt, samen met de duur van het bezoek en de pagina's die zijn bezocht. Het verzamelen van IP-adressen is een standaardgebruik en wordt door veel online diensten automatisch gedaan. We gebruiken IP-adressen om de gebruiksniveaus van de Dienst te berekenen, om serverproblemen te diagnosticeren en om de Dienst te beheren. We kunnen ook uw locatie bij benadering afleiden uit uw IP-adres.
                    </p>
                    <p>
                      <strong>Apparaatinformatie</strong>: We kunnen informatie
                      verzamelen over uw mobiele toestel, zoals een unieke
                      toestelidentificatie, om te begrijpen hoe u gebruikt maakt
                      van de Dienst.
                    </p>
                    <h3>HOE WE INFORMATIE GEBRUIKEN EN DOORZENDEN</h3>
                    <p>
                    We gebruiken en geven&nbsp;de informatie die u aan ons hebt verstrekt door zoals het aan u wordt uitgelegd waar u de gegevens verstrekt. Zie het gedeelte ‘Keuzes en toegang’ hieronder om te weten te komen hoe u kunt kiezen om een bepaald gebruik of&nbsp;doorgifte te weigeren.
                    </p>
                    <p>
                    Waar vereist door de toepasselijke wetgeving zullen wij op het moment van de gegevensverzameling uw toestemming vragen voor ons gebruik van uw persoonsgegevens.&nbsp; We kunnen, waar nodig, ook informatie van of over u gebruiken om een contract uit te voeren, om te voldoen aan een wettelijke verplichting (bijvoorbeeld voor onze verplichtingen inzake geneesmiddelenbewaking) of voor onze gerechtvaardigde zakelijke belangen.&nbsp; We kunnen ons ook baseren op andere rechtsgrondslagen, met name voor:
                    </p>
                    <ul>
                      <li>
                        <strong>
                        Het aanbieden van de functionaliteiten van de Dienst en het voldoen aan uw verzoeken.
                        </strong>
                        <ul>
                          <li>
                            om u de functionaliteit van de dienst aan te bieden
                            en u de bijbehorende klantenservice te verlenen;
                          </li>
                          <li>
                            om uw vragen te beantwoorden en aan uw verzoeken te
                            voldoen, zoals het verzenden van documenten waarom u
                            verzoekt of het sturen van e-mailherinneringen;
                          </li>
                          <li>
                          om u belangrijke informatie toe te sturen met betrekking tot onze relatie met u of betreffende de Dienst, of over wijzigingen in onze algemene voorwaarden, beleidsregels en/of andere administratieve informatie.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      Wij zullen deze activiteiten uitvoeren om onze
                      contractuele relatie met u te beheren en/of om een
                      wettelijke verplichting na te komen.
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Het behartigen van onze zakelijke doeleinden.
                        </strong>
                        <ul>
                          <li>
                            voor gegevensanalyse, bijvoorbeeld om de efficiëntie
                            van de Dienst te verbeteren;
                          </li>
                          <li>
                            voor audits, om te controleren of onze interne
                            processen functioneren zoals bedoeld en te voldoen
                            aan juridische, wettelijke of contractuele
                            vereisten;
                          </li>
                          <li>
                            voor fraude- en beveiligingscontrole, bijvoorbeeld
                            om cyberaanvallen of pogingen tot het plegen van
                            identiteitsdiefstal te detecteren en te voorkomen;
                          </li>
                          <li>
                            voor het ontwikkelen van nieuwe producten en
                            diensten;
                          </li>
                          <li>
                            voor het aanvullen, verbeteren of wijzigen van onze
                            website of onze producten en diensten;
                          </li>
                          <li>
                            voor het identificeren van trends in het gebruik van
                            de Dienst, bijvoorbeeld om inzicht te krijgen in
                            welke onderdelen van onze Dienst het meest
                            interessant zijn voor gebruikers; en
                          </li>
                          <li>
                            voor het bepalen van de doeltreffendheid van onze
                            promotiecampagnes, zodat we onze campagnes kunnen
                            aanpassen aan de behoeften en interesses van onze
                            gebruikers.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      Wij zullen deze activiteiten uitvoeren om onze
                      contractuele relatie met u te beheren, om een wettelijke
                      verplichting na te komen, en/of omdat om onze
                      gerechtvaardigd belangen te behartigen.
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Analyse van persoonlijke gegevens om gepersonaliseerde
                          diensten aan te bieden.
                        </strong>
                        <ul>
                          <li>
                            om u beter te begrijpen, zodat we onze interacties
                            met u kunnen personaliseren en u informatie en/of
                            aanbiedingen kunnen verstrekken die zijn afgestemd
                            op uw interesses;
                          </li>
                          <li>
                            om uw voorkeuren beter te begrijpen, zodat we via de
                            Dienst content kunnen leveren die volgens ons
                            relevant en interessant voor u zal zijn.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      Wij zullen gepersonaliseerde diensten verlenen met uw
                      toestemming of omdat om onze gerechtvaardige belangen te
                      behartigen.
                    </p>
                    <p>
                      Wij geven ook informatie die via de Dienst is verzameld
                      door:
                    </p>
                    <ul>
                      <li>
                        aan onze filialen voor doeleinden beschreven in dit
                        Privacybeleid. Een lijst van onze filialen is
                        beschikbaar op{" "}
                        <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">
                        https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                        </a>{" "}
                        &nbsp;- toegang tot de toekomstige deponeringen van Kenvue bij SEC (Securities and Exchange Commission).{" "}
                        Johnson &amp; Johnson Consumer Bv is
                        de partij die verantwoordelijk is voor het beheer van de
                        gezamenlijk gebruikte Persoonsgegevens;
                      </li>
                      <li>
                        aan onze externe partners met wie wij gezamenlijk een
                        promotie aanbieden;
                      </li>
                      <li>
                      aan onze externe dienstverleners die diensten leveren zoals websitehosting en -beheer, hosting van mobiele toepassingen, gegevensanalyse, betalingsverwerking, orderverwerking, aanbieden van infrastructuur, IT-diensten, klantenservices, e-mail en directe maildiensten, auditing en andere diensten om hen in staat te stellen diensten te leveren; en
                      </li>
                      <li>
                      zoals toegelaten door de toepasselijke wetgeving, aan een externe partij in het geval van een reorganisatie, fusie, verkoop, joint venture, toewijzing, overdracht of andere afstoting van alle of een deel van ons bedrijf, onze activa of onze aandelen (ook in verband met een faillissement of vergelijkbare procedures).
                      </li>
                    </ul>
                    <p>
                    Daarnaast gebruiken en geven we mogelijks uw informatie door voor zover wij dat noodzakelijk of geschikt achten: (a) om te voldoen aan een juridisch proces of de geldende wetgeving, waaronder mogelijk&nbsp;wetten buiten het land waar u woont; (b) om zoals toegelaten door de toepasselijke wetgeving aan verzoeken te voldoen van openbare en overheidsinstanties, waaronder mogelijk&nbsp;instanties buiten het land waar u woont; (c) om onze algemene voorwaarden te bekrachtigen; en (d) om de rechten, privacy, veiligheid of eigendom van ons, onze filialen, u of anderen te beschermen. We kunnen uw informatie ook gebruiken en doorgeven op andere manieren, nadat wij uw toestemming daartoe gekregen hebben.
                    </p>
                    <p>
                    We kunnen informatie die we automatisch verzamelen gebruiken en doorgeven zoals eerder beschreven in het gedeelte ‘Automatische&nbsp;verzameling en gebruik van informatie’.
                    </p>
                    <p>
                    Waar toegelaten door de toepasselijke wetgeving kunnen we daarnaast informatie die personen niet kan identificeren, gebruiken en doorgeven voor welk doeleinde dan ook. Als we informatie die niet persoonlijk identificeerbaar is combineren met informatie die wel identificeerbaar is (zoals het combineren van uw naam met uw geografische locatie) zullen wij de gecombineerde informatie behandelen als persoonsgegevens zolang als deze gecombineerd is.
                    </p>
                    <h3>KEUZES EN TOEGANG</h3>
                    <p>
                      <strong>
                        Uw keuzes met betrekking tot ons gebruik en doorgifte
                        van uw persoonsgegevens
                      </strong>
                      <br />
                      Wij bieden u keuzes met betrekking tot ons gebruik en
                      doorgifte van uw persoonsgegevens voor
                      marketingdoeleinden. U kunt bezwaar maken tegen:
                    </p>
                    <ul>
                      <li>
                        <strong>Marketingcommunicatie ontvangen van ons</strong>
                        : Als u in de toekomst niet langer marketingcommunicatie
                        van ons wilt ontvangen, kunt u bezwaar maken tegen
                        de&nbsp;ontvangst ervan&nbsp;door contact met ons op te
                        nemen via{" "}
                        <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                          contactformulier
                        </a>
                        . Vermeld in uw verzoek aan ons uw naam, geef de vorm(en) van marketingcommunicatie aan die u niet langer wenst te ontvangen en vermeld de adres(sen) waar deze naartoe wordt/worden gestuurd. Als u bijvoorbeeld niet langer marketing-e-mails of directe mail van ons wenst te ontvangen, kunt u dat aangeven. Vermeld hierbij uw naam en e-mail of postadres. U kunt ook kiezen geen marketingmededelingen meer van ons te ontvangen door naar de Dienst te gaan en uw onlineprofiel bij te werken. Daarnaast kunt u bezwaar maken tegen de ontvangst van marketing-e-mails door de instructies te volgen die in een dergelijk bericht gegeven worden.
                      </li>
                      <li>
                        <strong>Herinneringen ontvangen van ons</strong>: Als u
                        niet langer medische herinneringen van ons wilt
                        ontvangen, kunt u bezwaar maken tegen de ontvangst ervan
                        door contact met ons op te nemen via{" "}
                        <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                          contactformulier
                        </a>
                        . Vermeld in uw antwoord aan ons uw naam en e-mailadres
                        of telefoonnummer waarop u de herinneringen van ons
                        ontvangt.
                      </li>
                      <li>
                        <strong>
                          Het delen van uw persoonsgegevens met filialen en
                          externe partners
                        </strong>
                        : Als u er eerder voor hebt gekozen om
                        marketingcommunicatie te ontvangen van onze filialen of
                        externe partners, kan u bezwaar maken tegen het delen
                        van uw persoonsgegevens met die partijen voor hun
                        direct-marketingdoelen door contact met ons op te nemen
                        via{" "}
                        <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                          contactformulier
                        </a>
                        . Vermeld in uw antwoord aan ons dat wij niet langer uw
                        persoonsgegevens mogen delen met onze filialen en/of
                        externe partners voor hun marketingdoeleinden, en
                        vermeld uw naam en e-mailadres.
                      </li>
                    </ul>
                    <p>
                    Wij proberen zo snel als redelijk mogelijk aan uw verzoek(en) te voldoen. Wij wijzen u erop dat als u bezwaar maakt zoals hierboven beschreven, wij niet in staat zullen zijn om uw persoonsgegevens te verwijderen uit de databases van onze filialen waarmee wij reeds uw persoonsgegevens hebben gedeeld (d.w.z. vanaf de datum dat we uw verzoek tot bezwaar hebben verwerkt). We zullen echten redelijke inspanningen leveren om onze filialen op de hoogte te stellen van uw verzoek. Als u ervoor kiest geen marketinggerelateerde berichten meer van ons te ontvangen, kunnen wij u nog wel belangrijke transactionele en administratieve berichten sturen waartegen u geen bezwaar kan maken.
                    </p>
                    <p>
                      <strong>
                        Hoe u inzage&nbsp;kunt krijgen van uw persoonsgegevens,
                        ze kan verbeteren of wissen
                      </strong>
                      <br />
                      Als u uw persoonsgegevens wilt nakijken, verbeteren, bijwerken, wissen of hun verwerking beperken, of als u een elektronische kopie van uw persoonsgegevens wil aanvragen om ze te kunnen doorsturen naar een ander bedrijf (in de mate dat de toepasselijke wetgeving u deze rechten toekent), neemt u contact met ons op via{" "}
                      <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                        contactformulier
                      </a>
                      . We zullen uw verzoek zo snel als redelijk mogelijk beantwoorden, en niet later dan een maand na ontvangst. Als omstandigheden een vertraging van ons antwoord veroorzaken, zal u onmiddellijk op de hoogte gebracht worden en een datum ontvangen waarop wij u zullen antwoorden. U kan de Dienst ook bezoeken om uw online profiel bij te werken.
                    </p>
                    <h3>GRENSOVERSCHRIJDENDE DOORGIFTE</h3>
                    <p>
                    Uw persoonsgegevens kunnen in elk land waar we faciliteiten of dienstverleners hebben worden opgeslagen en verwerkt. Door onze Dienst te gebruiken of door ons toestemming te geven (waar wettelijk verplicht), kan uw informatie doorgegeven worden naar andere landen dan het land waar u woont, inclusief de Verenigde Staten, waar andere gegevensbeschermingwetten kunnen gelden dan in uw eigen land. Niettemin, gepaste contractuele en andere maatregelen zijn getroffen om persoonsgegevens te beschermen wanneer ze verzonden&nbsp;worden naar onze filialen of derden in andere landen.
                    </p>
                    <p>
                      Sommige landen buiten de Europese Economische Ruimte (EER)
                      worden erkend door de Europese Commissie als landen
                      waar&nbsp;een gepast niveau van gegevensbescherming geldt
                      volgens de EER normen (de volledige lijst van deze landen
                      is{" "}
                      <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_nl">
                        hier
                      </a>{" "}
                    beschikbaar. Voor doorgiften vanuit de EER naar landen die naar oordeel van de Europese Commissie niet voldoende bescherming bieden, hebben we verzekerd dat afdoende maatregelen getroffen werden, onder andere door te verzekeren dat de ontvanger gebonden is aan <strong>EU&nbsp;standaardbepalingen inzake gegevensbescherming</strong>, om uw persoonsgegevens te beschermen. U kan een kopie van deze maatregelen ontvangen door contact op te nemen met onze functionaris voor gegevensbescherming zoals uitgelegd in het onderstaande gedeelte&nbsp;‘Contact met ons opnemen’.
                    </p>
                    <h3>BEVEILIGING</h3>
                    <p>
                    Wij streven ernaar gebruik te maken van redelijke organisatorische, technische en administratieve maatregelen die ontworpen zijn om de persoonsgegevens in ons bezit te beschermen. Helaas kan voor geen enkele gegevensoverdracht over het internet noch voor enig gegevensopslagsysteem een 100% veiligheidsgarantie worden geboden. Als u redenen hebt om te geloven dat uw interactie met ons niet langer veilig is (bijvoorbeeld als u meent dat de beveiliging van een account die u bij ons heeft, is aangetast), breng ons daar dan onmiddellijk van op de hoogte, in overeenstemming met de onderstaande gedeelte ‘Contact met ons opnemen’.
                    </p>
                    <h3>BEWAARPERIODE</h3>
                    <p>
                    We zullen uw persoonsgegevens bewaren voor zo lang als nodig of toegelaten is voor het doel/de doelen waarvoor ze verkregen werden. De criteria die gebruikt worden om onze bewaarperiodes te bepalen, omvatten: (i) de duur van onze lopende relatie met u en van de Dienst die we&nbsp;u aanbieden; (ii) of er al dan niet een wettelijke verplichting is waaraan wij onderworpen zijn; en (iii) of bewaring al dan niet raadzaam is wegens onze juridische positie (zoals aangaande toepasselijke verjaringstermijn(en), een rechtszaak of onderzoeken van een toezichthouder).
                    </p>
                    <p>Houd er echter rekening mee dat dit geen alomvattende lijst van bewaarperiodes is. Uw persoonsgegevens kunnen voor een langere periode bewaard worden aan de hand van de criteria uit de eerste paragraaf van dit gedeelte, in het bijzonder punten (ii) en (iii).</p>
                    <h3>WEBSITES EN DIENSTEN VAN DERDEN</h3>
                    <p>
                    Deze Dienst kan links bevatten naar sites van derden. Dit Privacybeleid is niet van toepassing op en wij zijn niet verantwoordelijk voor de privacy, informatie en praktijken van derden, inclusief derden die een site of online dienst exploiteren (inclusief, zonder beperking, alle toepassingen) die beschikbaar is via deze Dienst of waarnaar deze Dienst een link bevat. De beschikbaarheid van of het plaatsen van een link naar een dergelijke site of dienst op de Dienst houdt geen aanbeveling in van ons of onze filialen.
                    </p>
                    <h3>CONTACT MET ONS OPNEMEN</h3>
                    <p>
                    Johnson &amp; Johnson Consumer BV, te Stadsplateau 27-29, 3521 AZ Utrecht, The Netherlands, is het bedrijf verantwoordelijk voor het verzamelen, het gebruik en de doorgifte van persoonsgegevens volgens dit Privacybeleid.
                    </p>
                    <p>
                      Neem als u vragen hebt over dit Privacybeleid contact op
                      met ons via{" "}
                      <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                        contactformulier
                      </a>{" "}
                      of schrijf naar het volgende adres:
                    </p>
                    <p>
                      
                      Johnson &amp; Johnson Consumer BV
                      <br />
                        Stadsplateau 27-29,
                        <br />
                        3521 AZ Utrecht,
                        <br />
                        The Netherlands
                      
                    </p>
                    <p>
                      U kan ook contact opnemen met onze functionaris voor
                      gegevensbescherming die verantwoordelijk is voor uw land
                      of regio, indien van toepassing, via{" "}
                      <a href="mailto:emeaprivacy@kenvue.com">
                        emeaprivacy@kenvue.com
                      </a>
                      .
                    </p>
                    <h3>EEN KLACHT INDIENEN BIJ EEN TOEZICHTHOUDER</h3>
                    <p>
                      U kan een klacht indienen bij een toezichthoudende
                      autoriteit die bevoegd is voor uw land of regio. Klik{" "}
                      <a href="https://edpb.europa.eu/about-edpb/board/members_nl">
                        hier
                      </a>{" "}
                      voor de contactinformatie van dergelijke autoriteiten.
                    </p>
                    <h3>UPDATES VAN DIT PRIVACYBELEID</h3>
                    <p>
                    Wij kunnen dit Privacybeleid wijzigen. Veranderingen in dit Privacybeleid treden in werking als wij het herziene Privacybeleid op de Dienst plaatsen. Uw gebruik van de Dienst na deze wijzigingen betekent dat u akkoord gaat met het herziene Privacybeleid. We bevelen aan dat u het Privacybeleid regelmatig naleest wanneer u de Dienst bezoekt. Dit beleid werd het laatst bijgewerkt op <strong>02.06.2023</strong>.
                    </p>
                    <p />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="bg-blue zij-menu p-3">
                    <a href="/service" className="level-1 d-block ">
                      Service &amp; veel gestelde vragen
                    </a>
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="level-2 d-block "
                    >
                      Veel gestelde vragen
                    </a>
                    <a
                      href="/service/belangrijke-links"
                      className="level-2 d-block "
                    >
                      Belangrijke links
                    </a>
                    <a
                      href="/service/contact"
                      className="level-2 d-block "
                    >
                      Contact
                    </a>
                    <a
                      href="/service/juridische-kennisgeving"
                      className="level-2 d-block "
                    >
                      Juridische kennisgeving
                    </a>
                    <a
                      href="/service/privacybeleid"
                      className="level-2 d-block text-rood"
                    >
                      Privacybeleid
                    </a>
                    <a
                      href="/service/cookies"
                      className="level-2 d-block "
                    >
                      Cookiebeleid
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="B00544">
          <div className="container-fluid pb-3">
            <div className="container">
              <div className="row mr-md-5 pr-md-5">
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout1_jongen.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor jongens"
                    />
                  </a>
                  <h3>Materiaal voor jongens</h3>
                  <div className="pay-off">
                    Lesmateriaal rondom het thema puberteit bij jongens
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu downloaden
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout2_primair_onderwijs.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor basisscholen"
                    />
                  </a>
                  <h3>Materiaal voor basisscholen</h3>
                  <div className="pay-off">
                    Lesmateriaal voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu samenstellen
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout3_lesvoorbereiding.png"
                      className="img-fluid p-3"
                      alt="Hulp bij de lesvoorbereiding"
                    />
                  </a>
                  <h3>Hulp bij de lesvoorbereiding</h3>
                  <div className="pay-off">
                    Tips en checklists voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu informeren
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a href="/lesmaterialen" className="stretched-link">
                    <img
                      src="/uploads/callout5_lesmateriaal.png"
                      className="img-fluid p-3"
                      alt="Download lesmaterialen"
                    />
                  </a>
                  <h3>Download lesmaterialen</h3>
                  <div className="pay-off">
                    Uitgebreid aanbod van onderwerpen voor de seksuele
                    voorlichting
                  </div>
                  <a
                    href="/lesmaterialen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Naar de materialen
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a href="/multimedia" className="stretched-link">
                    <img
                      src="/uploads/callout6_multimedia.png"
                      className="img-fluid p-3"
                      alt="Multimedia voor seksuele voorlichting"
                    />
                  </a>
                  <h3>Multimedia voor seksuele voorlichting</h3>
                  <div className="pay-off">
                    Instructiefilm en interactieve kennistests voor seksuele
                    voorlichting
                  </div>
                  <a
                    href="/multimedia"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bekijken
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout4_lespakket_bestellen.png"
                      className="img-fluid p-3"
                      alt="Gratis lespakket"
                    />
                  </a>
                  <h3>Gratis lespakket</h3>
                  <div className="pay-off">
                    Relevante lesmaterialen voor seksuele voorlichting
                  </div>
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bestellen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container pt-3 hideMegaMenu">
            <div className="text-center">
              <img
                className="pb-5"
                style={{
                  width: "290px"
                }}
                src="/uploads/ob-logo.png"
                alt="o.b. professioneel forum"
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <a className="main" href="/">
                  Homepage
                </a>
                <a href="/service/sitemap">Sitemap</a>
                <a href="/service/contact">Contact</a>
                <a href="/service/juridische-kennisgeving">
                  Juridische kennisgeving
                </a>
                <a href="/service/privacybeleid">Privacy statement</a>
                <a href="/service/cookies">Cookie statement</a>
              </div>
              <div className="col-md-4">
                <a
                  className="main"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
                <a href="/lesmaterialen/lespakket-gratis-bestellen">
                  Bestel het nu
                </a>                  
              </div>
              <div className="col-md-4">
                <a className="main" href="/multimedia">
                  Multimedia voor seksuele voorlichting
                </a>
                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">
                  Educatieve film "Opgroeien"
                </a>
                <a href="/multimedia/online-kennistests/quiz-puberteit">
                  Quiz puberteit
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie">
                  Quiz menstruatie
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene">
                  Quiz menstruatiehygiëne
                </a>
                <a href="/multimedia/oefenbladen">Oefenbladen</a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <a className="main" href="/lesmaterialen">
                  Lesmaterialen voor seksuele voorlichtingslessen
                </a>
                <a href="/lesmaterialen/themagebieden">Themagebieden</a>
                <a href="/lesmaterialen/speciale-pakketten">
                  Speciale pakketten
                </a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/lessen">
                  Lesgeven
                </a>
                <a href="/lessen/richtlijnen">
                  Richtlijnen voor seksuele voorlichting
                </a>
                <a href="/lessen/lesvoorbereiding">Lesvoorbereiding</a>
                <a href="/lessen/tips">Tips voor seksuele voorlichting</a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/service">
                  Service en veelgestelde vragen
                </a>
                <a href="/service/veel-gestelde-vragen">
                  Veelgestelde vragen
                </a>
                <a href="/service/belangrijke-links">Belangrijke links</a>
                <a href="/service/contact">Contact &amp; feedback</a>
              </div>
            </div>
            <div className="row mt-5 pb-3">
              <div className="col-12 font-smaller">
                <p>© Johnson &amp; Johnson Consumer B.V. 2006 - 2019.</p>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-instellingen</button>
                <p style={{"float": "left"}}>
                  Deze website is gepubliceerd door Johnson &amp; Johnson                               
                  Consumer B.V., die als enige verantwoordelijk is voor de
                  inhoud. De site is bedoeld voor een Nederlands publiek.
                  <br />
                  Voor het laatst bijgewerkt: 14 oktober 2019.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <script type="text/javascript" src="/js/jquery-3.5.1.js" />
        <script type="text/javascript" src="/js/popper.min.js" />
<script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
        <script type="text/javascript" src="/js/bootstrap.min.js" />
        <script type="text/javascript" src="/js/mdb.min.js" />
        <div className="hiddendiv common" />
        <script type="text/javascript" src="/system/js/user.js" />
      </div>
    );
  }
}

export default Page;
